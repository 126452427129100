import strings from "./constants/strings";

function App() {
  return (
    <div className="flex flex-col h-screen w-screen justify-center items-center bg-home-background bg-cover bg-center font-urbanist">
      <p className="text-white text-3xl font-bold">{strings.healthlyticsCanada}</p>
      <p className="text-white text-8xl mt-7 font-bold">{strings.comingSoon}</p>
      {/* Fonts example */}
      {/* <p className="font-light">This text is light (100).</p>
      <p className="font-normal">This text is normal (400).</p>
      <p className="font-bold">This text is bold (700).</p>
      <p className="font-extrabold">This text is extra bold (800).</p>
      <p className="italic font-extrabold">This text is extra bold and italic (800).</p> */}
      {/* ------------------- */}
    </div>
  );
}

export default App;
